<template>
  <div class="wrap-content">
    <div class="header">
      <h2>数据目录填报</h2>
      <ul></ul>
      <div class="button">
        <CommonHeader />
      </div>
    </div>
    <div class="main">
      <div class="card">
        <h2>2023年度数据目录填报</h2>
        <div class="legend" @mouseenter="isHover = true" @mouseleave="isHover = false" @click="handleClick">
          <img src="../../assets/img/ndsjmlbs-mr.png" alt="" v-if="!isHover" />
          <img src="../../assets/img/ndsjmlbs-xz.png" alt="" v-if="isHover" />
        </div>
        <p>
          本年度无重要或核心数据目录?
          <span class="link" @click="uploadExplain">上传声明文件</span>
        </p>
      </div>
    </div>
    <Footer />
    <explainModal ref="explainModalRef" />
  </div>
</template>

<script setup>
import CommonHeader from "@/components/commonHeader.vue";
import Footer from "@/components/footer.vue";
import { ref, inject, onMounted } from "vue";
import explainModal from "./components/explainModal.vue";
import { useRouter } from "vue-router";
let isHover = ref(false);


let router = useRouter();

//进行数据填报
const handleClick = () => {
  let currentYear = new Date().getFullYear();
  let url = `/message/content/check?year=${currentYear}`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      if (data.isHashOldData) {
        router.push({ name: "FillingList" });
      } else {
        router.push({
          name: "selectLevel"
        })
      }
    }
  });



}
//上传说明文件
let explainModalRef = ref(null);
const uploadExplain = () => {
  explainModalRef.value.modify();
}


let axios = inject("axios");
let isDisabled = ref(false);

//验证数据目录跳转路径
const validatePath = () => {
  let currentYear = new Date().getFullYear();
  let url = `/message/content/check?year=${currentYear}`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      if (data.isOpenFill) {
        if (data.isDeclare || data.isCompleted) {
          router.push({ name: "fillSuccess" });
        } else if (data.isHasData) {
          router.push({ name: "FillingList" });
        } else {
          router.push({ name: "movement" });
        }
      } else {
        router.push({ name: "fillSuccess" });
      }
    }
  });
};
onMounted(() => {
  validatePath();
});
</script>

<style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  min-height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 15px 0;
  height: 70px;
  > h2 {
    font-family: PingFangSC-Medium;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #111111;
  }
  ul {
    display: flex;
    li {
      cursor: pointer;
      color: #888888;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      height: 40px;
      width: 150px;
      letter-spacing: 0px;
      text-align: center;
      color: #111111;
    }
    li.active {
      background: #f6f6f6;
      border-radius: 10px;
      color: #111111;
      font-weight: bold;
    }
  }
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 20px;
  }
  .btn-add {
    color: #fff;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}
.custom-btn {
  border: 1px solid #606060;
  color: #606060;
  border-radius: 6px;
  padding: 3px 20px;
  font-size: 14px;
}
.main {
  min-height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  align-content: center;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  h2 {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    color: #111111;
    // line-height: 33px;
    margin-bottom: 60px;
    text-align: center;
  }
  .legend {
    width: 500px;
    height: 320px;
    background-image: linear-gradient(#eaeffd, #eaeffd),
      linear-gradient(#f9f9f9, #f9f9f9);
    background-blend-mode: normal, normal;
    border-radius: 20px;
    border: solid 3px #eaeffd;
    box-sizing: border-box;
    position: relative;
    img {
      display: block;
      width: 420px;
      height: 240px;
      position: absolute;
      left: 50%;
      bottom: -3px;
      transform: translate(-50%, 0);
    }
  }
  .legend:hover {
    background-image: linear-gradient(#eaeffd, #eaeffd),
      linear-gradient(#f9f9f9, #f9f9f9);
    background-blend-mode: normal, normal;
    border: solid 3px #688aea;
    box-sizing: border-box;
    img {
      display: block;
      width: 420px;
      height: 300px;
      position: absolute;
      left: 50%;
      bottom: -3px;
      transform: translate(-50%, 0);
    }
  }
  > p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #555555;
    text-align: center;
    margin-top: 30px;
    .link {
      color: #3d55a8;
    }
    .link:hover {
      cursor: pointer;
      //   text-decoration: underline;
    }
  }
}
</style>